import {useCallback, useEffect, useRef, useState} from "react";
import {Button} from "./Button";
import {FACEMESH_TESSELATION, Holistic} from "@mediapipe/holistic";
import {drawConnectors} from '@mediapipe/drawing_utils'
import Webcam from "react-webcam";
import {CloseIcon} from "./CloseIcon";
import {useResponsive} from "../hooks/utils/useResponsive";
import {Camera} from "@mediapipe/camera_utils";
import {MAX_DEVICE_WIDTH_DESKTOP} from "../constants/responsive";

export const CameraView = ({onSubmit, onCancel}) => {
    const {height, width} = useResponsive()
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const [imageSource, setImageSource] = useState(null)

    const capture = useCallback(() => {
        const imageSource = webcamRef.current.getScreenshot();
        setImageSource(imageSource)
    }, [])

    const submitImageData = useCallback(() => {
        if (imageSource) {
            onSubmit(imageSource)
        }
    }, [imageSource, onSubmit])

    const recapture = () => {
        setImageSource(null)
    }

    const onResults = (results) => {
        console.log("results=>", results)

        if (!webcamRef.current?.video || !canvasRef.current) return

        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");
        if (canvasCtx == null) throw new Error('Could not get context');
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite existing pixels.
        canvasCtx.globalCompositeOperation = 'source-in';
        canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite missing pixels.
        canvasCtx.globalCompositeOperation = 'destination-atop';
        canvasCtx.drawImage(
            results.image, 0, 0, canvasElement.width, canvasElement.height);

        canvasCtx.globalCompositeOperation = 'source-over';

        drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION,
            {color: '#C0C0C070', lineWidth: 1});

        canvasCtx.restore();
    }

    useEffect(() => {
        if (imageSource) return;

        const holistic = new Holistic({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
            }
        });

        holistic.setOptions({
            selfieMode: true,
            modelComplexity: 1,
            smoothLandmarks: true,
            enableSegmentation: true,
            smoothSegmentation: true,
            refineFaceLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });
        holistic.onResults(onResults);

        if (
            typeof webcamRef.current !== "undefined" &&
            webcamRef.current !== null
        ) {
            if (!webcamRef.current?.video) return

            const camera = new Camera(webcamRef.current.video, {
                onFrame: async () => {
                    if (!webcamRef.current?.video) return

                    await holistic.send({image: webcamRef.current.video});
                },
                width,
                height,
            });
            camera.start();
        }

    }, [height, width, imageSource])


    return (
        <>
            {!imageSource &&
                <>
                    {/*<WebCam*/}
                    {/*    ref={webcamRef}*/}
                    {/*    errorMessages={{}}*/}
                    {/*/>*/}

                    <Webcam
                        ref={webcamRef}
                        mirrored={true}
                        screenshotFormat="image/jpeg"
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                            display: "hidden"
                        }}
                        height
                        // videoConstraints={{
                        //     height,
                        // }}
                    />
                </>

            }

            {!imageSource && (
                <>
                    {/*<div className="absolute left-0 right-0 top-0">*/}
                    {/*    <div className={'bg-black bg-opacity-50'}>*/}
                    {/*        <div className="container w-full mx-auto px-4">*/}
                    {/*            <Header showClose onClose={onCancel} className={'py-2'}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className={'flex flex-col items-center mt-4'}>*/}
                    {/*        <BoldText className={'text-white'}>*/}
                    {/*            Position your face within the frame.*/}
                    {/*        </BoldText>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="absolute left-0 right-0 top-0 bottom-0 justify-center">
                        <canvas
                            ref={canvasRef}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />
                    </div>

                    <div
                        className="absolute left-0 right-0 bottom-0 bg-black bg-opacity-50 flex flex-row justify-between items-center px-4 py-4">

                        <div>
                            <CloseIcon onClick={onCancel}/>
                        </div>

                        <div>
                            <Button onClick={capture} className={'py-2'}>
                                Capture
                            </Button>
                        </div>

                    </div>

                </>
            )
            }

            {imageSource &&
                <div className="flex flex-col items-center mt-4">
                    <div className={'border-2 border-white border-dashed'}>
                        <img
                            src={imageSource}
                            alt={"selfie"}
                            style={{
                                height: 'auto',
                                width: 'auto',
                                objectFit: 'contain',
                                maxWidth: MAX_DEVICE_WIDTH_DESKTOP,
                                minWidth: '100%',
                            }}
                        />
                    </div>

                    <div className={'max-w-sm mt-8'}>
                        <Button onClick={submitImageData} className={'w-full'}>
                            Continue
                        </Button>

                        <Button onClick={recapture} className={'w-full mt-4'} variant={'secondary'}>
                            Recapture
                        </Button>
                    </div>
                </div>
            }
        </>
    )
}
