import {PageContainer} from "../components/PageContainer";
import {Logo} from "../components/Logo";
import {BoldText} from "../components/BoldText";
import {Button} from "../components/Button";
import {LinkButton} from "../components/LinkButton";

export const Home = () => {
    return (
        <PageContainer className={'pt-[128px]'} showHeader={false}>
            <div className="flex flex-row">
                <div className="basis-1/2">
                    <Logo/>

                    <div className={'mt-12'}>
                        <BoldText className={'text-white uppercase text-[64px]'}>
                            AI Skin Analysis
                        </BoldText>

                        <div>
                            <BoldText className={'text-white uppercase text-[18px]'}>
                                Get real time analysis of your skin and recommendations for better skin health.
                            </BoldText>
                        </div>

                    </div>

                    <div className={'flex flex-row mt-8'}>
                        <LinkButton to={"/get-started"}>
                            Get Started
                        </LinkButton>

                        <Button variant={'secondary'} className={'ml-8'}>
                            Learn More
                        </Button>
                    </div>
                </div>
                <div className="basis-1/2">

                </div>
            </div>
        </PageContainer>
    )
}
