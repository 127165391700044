import {PageContainer} from "../components/PageContainer";
import {BoldText} from "../components/BoldText";
import {Button} from "../components/Button";
import {Text} from "../components/Text";
import {useState} from "react";
import {CameraView} from "../components/CameraView";
import {LoadingElement} from "../components/LoadingElement";
import {ConfirmationBox} from "../components/ConfirmationBox";
import {useNavigate} from "react-router-dom";
import {useCreateNewSkinAnalysisRequest} from "../hooks/api/useSkinAnalysisApi";
import {getFormarttedNumber} from "../utils/utils";

export const TakeSelfie = () => {
    const navigate = useNavigate();
    const mutation = useCreateNewSkinAnalysisRequest()

    const [enableCamera, setEnableCamera] = useState(false)
    const [analysis, setAnalysis] = useState(null)

    const handleEnablePermission = () => {
        setEnableCamera(true)
    }

    const onSubmit = (base64) => {
        setEnableCamera(false)
        setAnalysis(null)

        mutation.mutate(base64, {
            onSuccess: (res) => {
                setAnalysis(res.data)
            },
            onError: () => {

            }
        })
    }

    const onCompletePayment = () => {

    }

    const onCancel = () => {
        navigate("/get-started")
    }

    return (
        <PageContainer
            showClose
            onClose={onCancel}
            className={enableCamera ? 'bg-black' : ''}
            showHeader={!enableCamera}
        >
            {(analysis || mutation.isPending) &&
            <div className="flex flex-col items-center mt-8">
                <div className="flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg">
                    {mutation.isPending &&
                    <LoadingElement
                        title={"Preparing your photo"}
                        subTitle={"Please keep this window open"}
                    />
                    }

                    {analysis &&
                    <ConfirmationBox
                        title={"Upload complete"}
                        subTitle={`Please complete payment of ${analysis?.currency} ${getFormarttedNumber(analysis.amount)} to see your analysis results`}
                        btnLabel={"Complete payment"}
                        onBtnClick={() => {
                            navigate(`/payment/${analysis?.tracking_key}`)
                        }}
                    />
                    }
                </div>
            </div>
            }

            {(!analysis && !mutation.isPending) &&
            <>
                {enableCamera ?
                    <CameraView onSubmit={onSubmit} onCancel={onCancel}/>
                    :
                    <div className="flex flex-col items-center">
                        <div className={'flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg'}>
                            <BoldText className={'text-white uppercase text-[24px] text-center'}>
                                Allow camera permissions
                            </BoldText>

                            <Text className={'text-white mt-8 text-center'}>
                                For an optimal experience, our Virtual Skin Analyzer needs permission to use your device
                                camera.
                            </Text>

                            <div className={'mt-8'}>
                                <Button className={'w-full'} onClick={handleEnablePermission}>
                                    Enable permissions
                                </Button>
                            </div>

                            <Text className={'text-white text-sm mt-12'}>
                                Your image will be deleted after the analysis
                            </Text>
                        </div>
                    </div>
                }
            </>
            }

        </PageContainer>
    )
}
